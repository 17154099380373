import React from 'react';
import { URLAPI } from '../config';


const DivRadio = ({ id, title, image, children}) => {
    return (
        <div className="tab border_bottom">
            <input type="radio" id={id} name="rd" className='input_radio'/>
            <label className="tab-label d-flex" for={id}>
                {image &&
                    <img
                        className="image-radio"
                        src={URLAPI + image}
                        alt="avatar"
                    />
                }
                <p style={{marginLeft: '2em', paddingTop: 10}}>{title}</p>
            </label>
            <div className="tab-content">
                {children}
            </div>
        </div>
        
    )
}

export default DivRadio;