import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {PAY_ORDER_DOWNLOAD_PDF_PATH, URLAPI} from "../../config";
import {
    CONFIRM_QUOTATION_SUCCESS,
    confirm_quotation,
    clear_status_quotation,
    update_quotation_client,
    UPDATE_QUOTATION_CLIENT_SUCCESS,
    get_all_quotation,
    advance_payment_quotation,
    ADVANCE_PAYMENT_QUOTATION_SUCCESS
} from "../../redux/quotation";
import {create_webpay, clear_status_webpay, CREATE_WEBPAY_SUCCESS} from "../../redux/webpay";
import {get_all_shopping_cart} from "../../redux/shopping_carts";
import {get_all_order} from "../../redux/order";
import LoadingSpinner from "../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "../../components/modal";
import Input from "../../components/input";
import {theme} from "../../config";
import Whatsapp from "../../components/whatsapp";
import DivRadio from "../../components/div-radio";

const ItemQuotation = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const _get_all_quotation = useSelector((state) => state.quotation_module._get_all_quotation);
    const _get_all_type_payment = useSelector((state) => state.type_payment_module._get_all_type_payment);
    const _confirm_quotation = useSelector((state) => state.quotation_module._confirm_quotation);
    const _advance_payment_quotation = useSelector((state) => state.quotation_module._advance_payment_quotation);
    const _update_quotation_client = useSelector((state) => state.quotation_module._update_quotation_client);
    const {token} = useSelector((state) => state.users_module.login.data) || null;


    const [data, setData] = useState({
        fecha_creacion: "",
        codigo_interno: "",
        estado: "",
        costo: 0,
        valor_anticipo: 0,
        total: 0,
        ciudad: "",
        direccion: "",
        usuario: {
            id: 0,
            identificacion: "",
            nombres: "",
            apellidos: "",
            correo: "",
            telefono: ""
        },
        detalles: []
    });

    const [item, setItem] = useState([]);
    const [typePayments, setTypePayments] = useState([]);

    const [config, setConfig] = useState({
        moneda: '',
        cantidad_decimal: '',
        separador_decimal: '',
        porcentaje_anticipo: 0,
    });

    useEffect(() => {
        if (_get_all_quotation.data && _get_all_quotation.data.datos) {
            let value = _get_all_quotation.data.datos.filter(x => parseInt(x.id) === parseInt(id));
            if (value.length > 0) {
                setData(value[0]);
            }
            setConfig(_get_all_quotation.data.configuraciones);
        }
    }, [_get_all_quotation, id]);

    useEffect(() => {
        if (_get_all_type_payment.data && _get_all_type_payment.data.datos) {
            let values = _get_all_type_payment.data.datos.filter(x => x.activo === true);
            if (values.length > 0) {
                setTypePayments(values);
            }
        }
    }, [_get_all_type_payment, id]);

    useEffect(() => {
        if (_confirm_quotation.status === 200) {
            dispatch(clear_status_quotation(CONFIRM_QUOTATION_SUCCESS));
            dispatch(get_all_quotation(token, 1));
            /* history.push(`/mis-cotizaciones`); */
            setIsLoading(false);
            setModalConfirm(false);
        } else if (_confirm_quotation.status !== 0) {
            dispatch(clear_status_quotation(CONFIRM_QUOTATION_SUCCESS));
            toast.error(_confirm_quotation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_confirm_quotation, dispatch, token, history, id]);

    useEffect(() => {
        if (_update_quotation_client.status === 200) {
            dispatch(clear_status_quotation(UPDATE_QUOTATION_CLIENT_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_quotation(token, 1));
            dispatch(get_all_shopping_cart(token));
            document.getElementById("catalogo").className = "type_text text_black text_16";
            document.getElementById("cotizaciones").className = "type_text text_black text_16";
            document.getElementById("carrito").className = "type_text text_black text_16 active";
            document.getElementById("fabricaciones").className = "type_text text_black text_16";
            history.push(`/carrito-compra`);

        } else if (_update_quotation_client.status !== 0) {
            dispatch(clear_status_quotation(UPDATE_QUOTATION_CLIENT_SUCCESS));
            toast.error(_update_quotation_client.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_quotation_client, token, dispatch, history, id]);

    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalPayment, setModalPayment] = useState(false);
    const [modalMessagePayment, setModalMessagePayment] = useState(false);
    const [modalWebpay, setModalWebpay] = useState(false);
    const [url_ws, setUrlWs] = useState('');
    const [token_ws, setTokenWs] = useState('');
    const [file, setFile] = useState('');
    const [id_type_payment, setId_type_payment] = useState(null);

    useEffect(() => {
        if (_advance_payment_quotation.status === 200) {
            dispatch(clear_status_quotation(ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_quotation(token, 1));
            dispatch(get_all_order(token, 1));
            setModalPayment(false);
            setId_type_payment(null);
            setModalMessagePayment(true);
        } else if (_advance_payment_quotation.status !== 0) {
            dispatch(clear_status_quotation(ADVANCE_PAYMENT_QUOTATION_SUCCESS));
            toast.error(_advance_payment_quotation.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_advance_payment_quotation, dispatch, token, history, id]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields

        if (file === "") {
            toast.error("Debe seleccionar un comprobante", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }

        let advance = parseFloat(data.total) * parseFloat(config.porcentaje_anticipo) / 100;
        let datas = new FormData();
        datas.append(`comprobante`, file);
        datas.append(`anticipo`, advance);
        datas.append(`id_tipo_pago`, id_type_payment);

        dispatch(advance_payment_quotation(token, id, datas));
    }

    const _create_webpay = useSelector((state) => state.webpay_module._create_webpay);

    useEffect(() => {
        if (_create_webpay.status === 201) {
            setIsLoading(false);
            dispatch(clear_status_webpay(CREATE_WEBPAY_SUCCESS));
            if (_create_webpay && _create_webpay.data && _create_webpay.data.datos) {
                setTokenWs(_create_webpay.data.datos.token);
                setUrlWs(_create_webpay.data.datos.url);
                setModalWebpay(true);
            }
        } else if (_create_webpay.status !== 0) {
            dispatch(clear_status_webpay(CREATE_WEBPAY_SUCCESS));
            setIsLoading(false);
            toast.error(_create_webpay.message, {position: toast.POSITION.TOP_RIGHT});
        }
    }, [_create_webpay, dispatch]);

    function create_webpay_plus(type_payment_id) {
        setIsLoading(true);
        let advance = parseFloat(data.total) * parseFloat(config.porcentaje_anticipo) / 100;
        let datas = new FormData();
        datas.append(`id`, data.id);
        datas.append(`id_tipo_pago`, type_payment_id);
        datas.append(`tipo`, 'quotation');
        datas.append(`monto`, advance);
        dispatch(create_webpay(token, datas));
    }

    return (
        <div className="product_details mt-5">
            <div><ToastContainer/></div>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h2>{data.codigo}</h2>
                    </div>
                    <div className="col-6 text-right">
                        <span style={{color: theme(data.estado.toUpperCase())}}>{data.estado.toUpperCase()}</span>
                    </div>
                    <div className="col-12 mt-2">
                        <strong>Fecha de creación: </strong><span>{data.fecha_creacion}</span>
                    </div>
                    {(data.estado === 'En espera') ?
                        <>
                            <div className="col-12 mt-2 text-center">
                                <div className="banner_text mb-3">
                                    <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Hemos
                                        recibido tu cotización,
                                        nuestros asesores la responderán lo más pronto posible</h4>
                                </div>
                                <div className="table_desc">
                                    <div className="cart_page table-responsive">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th className="product_thumb">Imagen</th>
                                                <th className="product_name">Código interno</th>
                                                <th className="product_name">Producto</th>
                                                <th className="product_quantity">Cantidad</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.detalles.map((item) => (
                                                <tr data-bs-toggle="modal" data-bs-target="#modal_box"
                                                    style={{cursor: 'pointer'}} onClick={() => {
                                                    setItem(item);
                                                }}>
                                                    <td className="product_thumb"><img src={URLAPI + item.imagenes[0].url}
                                                                                   alt="" style={{width: '30%'}}/></td>
                                                    <td className="product_name">{item.codigo_interno}</td>
                                                    <td className="product_name">{item.titulo}</td>
                                                    <td className="product_quantity">{item.cantidad}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <button className="btn btn-outline-calendar-cliente1" style={{margin: 1}}
                                        onClick={() => {
                                            history.push(`/mis-cotizaciones`);
                                        }}>Atras
                                </button>
                            </div>
                        </>
                        :
                        (data) &&
                        <>
                            {(data.estado === 'Pendiente pago') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Tienes
                                            pendiente el
                                            pago del anticipo, por favor carga el comprobante una vez hayas
                                            realizado el pago. Puedes ver la información del pago en tu correo o
                                            descargar la orden de pago desde esta pantalla.</h4>
                                    </div>
                                </div>
                            )}
                            {(data.estado === 'Orden generada') && (
                                <div className="col-12">
                                    <div className="banner_text mb-3">
                                        <h4 className="mt-1" style={{color: theme(data.estado.toUpperCase())}}>Nos
                                            encontramos en el
                                            proceso de fabricación de tus joyas, te informaremos por correo
                                            electrónico cuando estén listas para retirar.</h4>
                                    </div>
                                </div>
                            )}
                            <div className="col-12 mt-3">
                                <div className="table_desc">
                                    <div className="cart_page table-responsive">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th className="product_thumb">Imagen</th>
                                                <th className="product_name">Código interno</th>
                                                <th className="product_name">Producto</th>
                                                <th className="product_quantity">Cantidad</th>
                                                <th className="product_quantity">Precio</th>
                                                <th className="product_quantity">Subtotal</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.detalles.map((item) => (
                                                <tr data-bs-toggle="modal" data-bs-target="#modal_box"
                                                    style={{cursor: 'pointer'}} onClick={() => {
                                                    setItem(item);
                                                }}>
                                                    <td className="product_thumb"><img src={URLAPI + item.imagenes[0].url}
                                                                                   alt="" style={{width: '30%'}}/></td>
                                                    <td className="product_name">{item.codigo_interno}</td>
                                                    <td className="product_name">{item.titulo}</td>
                                                    <td className="product_quantity">{item.cantidad}</td>
                                                    <td className="product-price">{config.moneda} {parseFloat(item.precio).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</td>
                                                    <td className="product_total">{config.moneda} {parseFloat(item.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {(data.estado === 'Cotizado') &&
                                        <div className="cart_submit">
                                            <button type="button" onClick={() => {
                                                setIsLoading(true);
                                                dispatch(update_quotation_client(token, id, {}));
                                            }}>Editar pedido
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="coupon_area">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="coupon_code right">
                                            <h3>Totales</h3>
                                            <div className="coupon_inner">
                                                <div className="cart_subtotal">
                                                    <p>Anticipo de fabricación</p>
                                                    <p className="cart_amount">{config.moneda} {parseFloat(data.valor_anticipo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>
                                                <small>Corresponde al {config.porcentaje_anticipo}% del total de la
                                                    fabricación, depósito o transferencia al confirmar la
                                                    fabricación</small>
                                                <div className="cart_subtotal ">
                                                    <p>Envio</p>
                                                    <p className="cart_amount">{config.moneda} {parseFloat(data.costo).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>

                                                <div className="cart_subtotal">
                                                    <p>Total</p>
                                                    <p className="cart_amount">{config.moneda} {parseFloat(data.total).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</p>
                                                </div>
                                                <div className="checkout_btn">
                                                    {(data.estado === 'Cotizado') &&
                                                        <button className="btn btn-calendar-cliente1"
                                                                style={{margin: 1}}
                                                                onClick={() => {
                                                                    setModalConfirm(true);
                                                                }}>Confirmar fabricación
                                                        </button>
                                                    }
                                                    {(data.estado === 'Pendiente pago') &&
                                                        <div style={{textAlign: "left"}} className="mb-3">
                                                            <div className="cart_subtotal">
                                                                <p>Métodos de pago</p>
                                                            </div>
                                                            {typePayments &&
                                                                typePayments.map(x => (
                                                                    <DivRadio
                                                                        id={x.id}
                                                                        title={x.nombre}
                                                                        image={x.imagen}
                                                                        children={
                                                                            <div className="text-center">
                                                                                {(x.pasarela === true) ? (
                                                                                    <button
                                                                                        className="btn btn-calendar-cliente1"
                                                                                        style={{margin: 1}}
                                                                                        disabled={isLoading}
                                                                                        onClick={() => {
                                                                                            create_webpay_plus(x.id)
                                                                                        }}>Continuar
                                                                                    </button>
                                                                                ) : (
                                                                                    <button
                                                                                        className="btn btn-calendar-cliente1"
                                                                                        style={{margin: 1}}
                                                                                        onClick={() => {
                                                                                            setModalPayment(true);
                                                                                            setId_type_payment(x.id);
                                                                                        }}>Cargar comprobante
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        }
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    }
                                                    {(data.estado === 'Orden generada') &&
                                                        <button className="btn btn-calendar-cliente1"
                                                                style={{margin: 1}}
                                                                onClick={() => {
                                                                    document.getElementById("catalogo").className = "type_text text_black text_16";
                                                                    document.getElementById("cotizaciones").className = "type_text text_black text_16";
                                                                    document.getElementById("fabricaciones").className = "type_text text_black text_16 active";
                                                                    document.getElementById("carrito").className = "type_text text_black text_16";
                                                                    history.push(`/fabricacion/` + data.orden);
                                                                }}>Ver orden de fabricación
                                                        </button>
                                                    }
                                                    {(data.estado === 'Pendiente pago') &&
                                                        <>
                                                            {/* eslint-disable-next-line */}
                                                            <a href={`${URLAPI}${PAY_ORDER_DOWNLOAD_PDF_PATH}${id}/`}
                                                               target="_blank"
                                                               className="btn btn-calendar-cliente1"
                                                               style={{margin: 1}}>Descagar
                                                                orden de pago</a>
                                                        </>
                                                    }
                                                    <button onClick={() => {
                                                        history.push(`/mis-cotizaciones`);
                                                    }}>Atras
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Modal className="modal-main" show={modalConfirm}>
                {isLoading && <LoadingSpinner/>}
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Confirmar fabricación</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Al confirmar la fabricación está aceptando los
                            valores de la cotización para las joyas incluidas, luego deberá proceder con el pago del
                            anticipo por el medio de pago seleccionado, se enviará la orden a tu correo electrónico</p>
                    </div>
                </div>
                <div className="text-center div_footer">
                    <button type="button" onClick={() => {
                        setIsLoading(true);
                        dispatch(confirm_quotation(token, id, {}));
                    }} className={`btn btn-calendar-cliente1 mb-1 button_div`} disabled={isLoading}>Confirmar
                    </button>
                    <button type="button" onClick={() => {
                        setModalConfirm(false);
                    }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                    </button>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalPayment}>
                {isLoading && <LoadingSpinner/>}
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Cargar comprobante</h5>
                        <Input
                            name="file"
                            label="Archivo"
                            type="file"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="text-center div_footer">
                        <button type="submit" className={`btn btn-calendar-cliente1 mb-1 button_div`}
                                disabled={isLoading}>Cargar
                        </button>
                        <button type="button" onClick={() => {
                            setModalPayment(false);
                            setId_type_payment(null);
                        }} className={`btn btn-outline-calendar-cliente1 button_div`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main" show={modalMessagePayment}>
                <div className="card-body">
                    <h5 className="titulo_modal type_text mb-2">Comprobante cargado</h5>
                    <div className="text-center">
                        <p className="type_text text_16 text_black">Validaremos el pago y te informaremos sobre el
                            proceso de fabricación de tus joyas, por favor revisa tu correo electrónico donde te
                            enviaremos toda la información.</p>
                    </div>
                </div>
                <div className="text-center div_footer">
                    <button type="button" onClick={() => {
                        history.push(`/mis-cotizaciones`);
                        setModalMessagePayment(false);
                    }} className={`btn btn-calendar-cliente1 mb-1 button_div`}>Entendido
                    </button>
                </div>
            </Modal>
            <div className="modal fade" id="modal_box" tabindex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal_body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        {item &&
                                            <div id="custCarousel" className="carousel slide border-carousel"
                                                 data-ride="carousel" align="center">
                                                <div className="carousel-inner">
                                                    {item.imagenes ?
                                                        item.imagenes.map((x, index) => (
                                                            <div
                                                                className={(index === 0 ? "carousel-item active" : "carousel-item")}>
                                                                <img src={URLAPI + x.url} alt="Hills"/>
                                                            </div>
                                                        ))
                                                        :
                                                        <div className="carousel-item active">
                                                            <img id="zoom1"
                                                                 src="../../../../assets/img/logo/no-image.png"
                                                                 data-zoom-image="../../../../assets/img/logo/no-image.png"
                                                                 alt="Hills"/>
                                                        </div>
                                                    }
                                                </div>
                                                {/* eslint-disable-next-line */}
                                                <a className="carousel-control-prev" href="#custCarousel"
                                                   data-slide="prev">
                                                    <span className="carousel-control-prev-icon"></span>
                                                </a>
                                                {/* eslint-disable-next-line */}
                                                <a className="carousel-control-next" href="#custCarousel"
                                                   data-slide="next">
                                                    <span className="carousel-control-next-icon"></span>
                                                </a>

                                                <ol className="carousel-indicators list-inline">
                                                    {item.imagenes &&
                                                        // eslint-disable-next-line
                                                        item.imagenes.map((x, index) => (
                                                            <li className={(index === 0 ? "list-inline-item active" : "list-inline-item")}>
                                                                {/* eslint-disable-next-line */}
                                                                <a id={'carousel-selector-' + index}
                                                                   className={(index === 0 ? "list-inline-item active" : "list-inline-item")}
                                                                   data-slide-to={index} data-target="#custCarousel">
                                                                    {/* eslint-disable-next-line */}
                                                                    <img src={URLAPI + x.url} className="img-fluid"/>
                                                                </a>

                                                            </li>
                                                        ))
                                                    }
                                                </ol>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12">
                                        <div className="modal_right">
                                            <div className="modal_title mb-10">
                                                <small>{item.codigo_interno}</small>
                                                <h2>{item.titulo}</h2>
                                            </div>
                                            <div className="modal_price mb-10">
                                                <span
                                                    className="new_price">{config.moneda} {parseFloat(item.subtotal).toFixed(`${config.cantidad_decimal}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, `${config.separador_decimal}`)}</span>
                                            </div>
                                            <div className="modal_description mb-15">
                                                <p>{item.descripcion}</p>
                                            </div>
                                            <div className="modal_social row">
                                                <h2>Detalles</h2>
                                                {item.valores &&
                                                    item.valores.map(a => (
                                                        <div className="col-4 mb-2">
                                                            <small>{a.atributo}</small><br/>
                                                            <strong>{a.valor}</strong>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modalWebpay}>
                {isLoading && <LoadingSpinner/>}
                <form action={url_ws}>
                    <div className="card-body">
                        <h5 className="titulo_modal type_text mb-2">Webpay plus</h5>
                        {/*eslint-disable-next-line*/}
                        <img src="../../../../assets/img/logo/webpay_plus.png"
                             data-zoom-image="../../../../assets/img/logo/no-image.png"/>
                        <Input
                            name="token_ws"
                            value={token_ws}
                            style={{display: 'none'}}
                        />
                    </div>
                    <div className="text-center div_footer">
                        {/* eslint-disable-next-line */}
                        <input id="btn_ws" type="submit" className="btn btn-calendar-cliente1 button_div"
                               value="Generar pago" formtarget="_blank" onClick={() => {
                            setModalWebpay(false);
                        }}></input>
                        <button type="button" onClick={() => {
                            setModalWebpay(false);
                        }} className={`btn btn-outline-calendar-cliente1 button_div mt-1`}>Cancelar
                        </button>
                    </div>
                </form>
            </Modal>
            <Whatsapp/>
        </div>
    );
};

export default ItemQuotation;
