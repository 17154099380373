import React, {useState, useEffect} from "react";
import Index from "./pages/user-auth";
import {BrowserRouter as Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {SocketContext, socket} from './config/ws-client';
import packageJson from '../package.json';

function App() {
    const _session = useSelector((state) => state.users_module._session_data);
    const [username, setUsername] = useState("general");

    function caching() {
        let version = localStorage.getItem('version');
        if (version !== packageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }
            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
        }
    };

    useEffect(() => {
        caching();
        if (_session.data) {
            setUsername(_session.data.usuario);
        }
    }, [_session]);

    return (
        <SocketContext.Provider value={{socket: socket(username)}}>
            <Route path="/">
                <Index/>
            </Route>
        </SocketContext.Provider>
    );
}

export default App;
